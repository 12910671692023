import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./Collaboration.css";
import axios from "axios";
import HeaderBackToBlog from "../../components/Header/HeaderBackToBlog.js";
import { getFirstName } from "../../handlers/dictionaryHandler.js";

const Collaboration = () => {
  const photographers = [
    {
      title: "ספיר טנצר בני ברק",
      subTitle: "100 שח 45 דק",
      phoneNumber: "505990650",
    },
    {
      title: "מוריה קלוגהפט ירושלים",
      subTitle: "180 שח",
      phoneNumber: "525844174",
    },
    {
      title: "יוסף כהן ירושלים והסביבה",
      subTitle: "200 שח",
      phoneNumber: "546386382",
    },
    {
      title: "ניצה מיכאילוב - צפת והסביבה",
      subTitle: "200 - 349 שח - תלוי במרחק, הנחה לחיילים",
      phoneNumber: "545777849",
    },
  ];

  const vudus = [
    {
      title: "זהבה וייס - נומרולגית",
      subTitle: "שיחה ראשונה במתנה!",
      phoneNumber: "526998966",
    },
  ];

  const consuls = [
    {
      title: 'ד"ר אגוזה וסרמן',
      subTitle: " מאמנת מלשכת המאמנים ",
      subTitle2: "מערכות יחסים, מציאת זוגיות וחתונה",
      phoneNumber: "505377525",
      photo: "EgozaW",
      price: '200 ש"ח',
    },
    {
      title: "נדב קוקורייב",
      subTitle: " מאמן מלשכת המאמנים ",
      subTitle2: "בניית חזון זוגי תוך חיבור לעוצמות",
      phoneNumber: "544964890",
      photo: "NadavK",
      price: '250 ש"ח',
    },
  ];

  const photographersClick = () => {
    if (document.getElementById("photographers").style.display === "block") {
      document.getElementById("photographers").style.display = "none";
    } else {
      document.getElementById("photographers").style.display = "block";
    }
  };

  const couponClick = () => {
    if (document.getElementById("coupons").style.display === "block") {
      document.getElementById("coupons").style.display = "none";
    } else {
      document.getElementById("coupons").style.display = "block";
    }
  };

  const vuduClick = () => {
    if (document.getElementById("vudus").style.display === "block") {
      document.getElementById("vudus").style.display = "none";
    } else {
      document.getElementById("vudus").style.display = "block";
    }
  };

  const consulClick = () => {
    if (document.getElementById("consul").style.display === "block") {
      document.getElementById("consul").style.display = "none";
    } else {
      document.getElementById("consul").style.display = "block";
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const tryToBid = async (cell) => {
    console.log("test - post");
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PtryToBid",
        { cell },
        config
      );
    } catch (error) {}
  };

  return (
    <form className="forum-main-form">
      <HeaderBackToBlog />

      <br></br>

      <div className="form-body">
        <div className="choose_room">
          <h1 className="h1-center">הטבות לחברי דאבל הכרויות</h1>

          {/* <div className="collabbtn" onClick={photographersClick}>
            צלמים מעולים לתמונות יפות
            <div className="camera" />
          </div> */}

          <div id="photographers" className="displayNone">
            <div className="sectionTitle"> רשימת צלמים, מוזמנים ליצור קשר </div>
            {photographers.map((currentPhotographer) => {
              return (
                <div className="photographline">
                  <div className="photographCol">
                    <div> {currentPhotographer.title} </div>
                    <div> {currentPhotographer.subTitle} </div>
                  </div>
                  <div
                    className="whatsupFloata"
                    onClick={() => tryToBid(currentPhotographer.phoneNumber)}
                  >
                    <a
                      href={`https://wa.me/972${currentPhotographer.phoneNumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="whatsupFloat" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="collabbtn" onClick={consulClick}>
            יעוץ מסובסד לחברי האתר
            <div className="therapy" />
          </div>

          <div id="consul" className="displayNone">
            <div className="smallArticle">
              {" "}
              <b>
                לפעמים ההבדל בין דייט כושל לקשר מוצלח - הוא ליווי מקצועי בקשר.
              </b>
              <br />
              כולם מבינים את חשיבות הדבר. מישהו ניטרלי מקצועי ששומע אותך, משקף
              לך, מדריך קצת אם צריך, ושם בשבילך.
              <br />
              מקדם ומעצים אותך לכיוון מציאת הזוגיות תוך עזרה עם ההתמודדיות
              שבדרך.
              <br />
              יעוץ טוב זה דבר שמקדם בכל מישורי החיים. כי זה לחזור לעצמנו,
              ולכוחות המופלאים הטמונים בנו.
              <br />
              מצורפת רשימה של אנשי מקצוע מעולים במחיר מסובסד לחברי האתר
              <br />
              אז תאמרו שהגעתם דרכנו :)
            </div>
            {consuls.map((cur) => {
              return (
                <div key={cur.article} className="tip1">
                  <div> {cur.article} </div>
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <div className="rightCol">
                        <div> {cur.title} </div>
                        <div> {cur.subTitle} </div>
                        <div className="subTitle2"> {cur.subTitle2} </div>
                        <div className="subTitle2">
                          {" "}
                          מחיר: {cur.price} למפגש{" "}
                        </div>
                        <div> {"ליצירת קשר:  0" + cur.phoneNumber} </div>
                      </div>
                      <div className="leftCol">
                        <div className={cur.photo} />

                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(cur.phoneNumber)}
                        >
                          <a
                            href={`https://wa.me/972${cur.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="whatsupTips" />
                          </a>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="collabbtn" onClick={couponClick}>
            אירועים וקופונים
            <div className="coupon" />
          </div>

          <div id="coupons" className="displayNone">
            <div className="sectionTitle">אירועים וקופונים </div>
            <br />
            {/* <div className=""> לא נמצאו קופונים בתוקף</div> */}

            <div className="">• סדנת קוקטיילים בטו בשבט בגבעת שמואל:)</div>
            <div className="footer-text">
              <a
                href="https://paypage.takbull.co.il/1PfzH"
                target="_blank"
                rel="noopener noreferrer"
              >
                קופון Dbmn13
              </a>
            </div>
            <br />

            <div className="">• הספר "לא יכול יותר לבד" של נועם קמין </div>
            <div className="footer-text">
              <a
                href="https://meshulam.co.il/s/58ffa4b3-3adb-db8e-3319-cee5f72196db"
                target="_blank"
                rel="noopener noreferrer"
              >
                קישור לספר בהנחה לחברי דאבל וחבריהם :)
              </a>
            </div>
            <br />
            <div className="footer-text">
              <a
                href="https://www.facebook.com/groups/941392596661531"
                target="_blank"
                rel="noopener noreferrer"
              >
                קבוצת הפייסבוק שלנו
              </a>
            </div>
          </div>
          {/* <div className="collabbtn" onClick={vuduClick}>
            יעוץ לא קונבנציונלי
            <div className="vudu" />
          </div> */}
          <br />
          <div className="footer-text">
            <a
              href="http://bit.ly/38pD7Ax"
              target="_blank"
              rel="noopener noreferrer"
            >
              מדריך 22 צעדים
            </a>
          </div>

          <div id="vudus" className="displayNone">
            <div className="sectionTitle"> </div>
            {vudus.map((currentVudu) => {
              return (
                <div className="photographline">
                  <div className="photographCol">
                    <div>
                      {" "}
                      <b>{currentVudu.title}</b>{" "}
                    </div>
                    <div> {currentVudu.subTitle} </div>
                  </div>
                  <div
                    className="whatsupFloata"
                    onClick={() => tryToBid(currentVudu.phoneNumber)}
                  >
                    <a
                      href={`https://wa.me/972${currentVudu.phoneNumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="whatsupFloat" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <br />
          <a
            href={`https://wa.me/972528870305?text=הי+רביד+אשמח+לשמוע+על+המעמד+זוגי+ליין,+הגעתי+אליך+מאתר+דאבל. ${getFirstName()}`}
            target="_blank"
            rel="noopener noreferrer"
            color="white"
          >
            <div className="sarashabat"></div> {/* לעמוד הפייס */}
          </a>

          <br />
          <a
            href={`https://wa.me/972544964890?text=הי+נדב+אשמח+לשמוע+על+רבע+שעה+אימון+זוגי+מתנה+,+הגעתי+אליך+מאתר+דאבל. ${getFirstName()}`}
            target="_blank"
            rel="noopener noreferrer"
            color="white"
          >
            <div className="nadavkurayev"></div> {/* לעמוד הפייס */}
          </a>

          <br />
          <a
            href="https://meshulam.co.il/s/58ffa4b3-3adb-db8e-3319-cee5f72196db"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="noamkamin"></div> {/* לעמוד הפייס */}
          </a>
          <br />
          <a
            href={`https://social-events222.com/`}
            target="_blank"
            rel="noopener noreferrer"
            color="white"
          >
            <div className="izharweb"></div> {/* לעמוד הפייס */}
          </a>

          <br />
          {/* <a
            href="https://chat.whatsapp.com/CTcTrGXnSLp6JqqKsE3Lz9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="nadavravak"></div> 
          </a>
          <br /> */}

          <div className="faceline">
            <div className="lbl">הצטרפו לקבוצת ווטסאפ שקטה לעדכונים</div>
            <a
              href={"https://chat.whatsapp.com/CC5ApSvw7lpIYoujWAzHkv"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="whatsuplink"></div>
            </a>
            <br />
          </div>
          <div className="footer-text">
            <a
              href="https://www.facebook.com/groups/941392596661531"
              target="_blank"
              rel="noopener noreferrer"
            >
              קבוצת הפייסבוק שלנו
            </a>
          </div>

          {/* <div className="collabbtn-grey" onClick={() => {}}>
            בתי קפה בוטיק
            <div className="coffee" />
          </div> */}
        </div>

        <br></br>
        <br></br>

        <Link to="/blog" style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
        <div className="lineDice" />
      </div>
    </form>
  );
};

export default Collaboration;
