import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import HeaderBack from "../../components/Header/HeaderBack";
import { getVip, getGender } from "../../handlers/dictionaryHandler.js";
import PrayMan from "../../Articles/prayMan";
import PrayWoman from "../../Articles/prayWoman";

const BlogRomy = () => {
  const [prayAreaVisible, setPrayAreaVisible] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  return (
    <form className="forum-main-form">
      <HeaderBack />

      <br></br>

      <div className="form-body">
        <div id="h1"> הבלוג</div>

        <div className="">
          <Link to={"/fundates"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">פעילויות לדייטים</div>

              <div className="fundatesicon" />
            </div>
          </Link>
          <Link to={"/article"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">טיפים ומאמרים</div>

              <div className="owlicon" />
            </div>
          </Link>
          <Link to={"/collaboration"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">שת"פים ומבצעים</div>
              <div className={"presenticon"} />
            </div>
          </Link>

          <br />

          <div
            className="section-title"
            style={{
              fontSize: "14px",
              textDecoration: "underline",
              margin: "3px",
            }}
          >
            <a
              href={"https://chat.whatsapp.com/CC5ApSvw7lpIYoujWAzHkv"}
              target="_blank"
              rel="noopener noreferrer"
            >
              • קבוצת ווטסאפ לעדכונים
            </a>
          </div>

          <div
            className="section-title"
            style={{
              // fontSize: "11px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setPrayAreaVisible(!prayAreaVisible);
            }}
          >
            {" "}
            • תפילה לזיווג
          </div>
          <div className={prayAreaVisible ? "smallArticle" : "invisible"}>
            {getGender() === "1" ? <PrayWoman /> : <PrayMan />}
          </div>

          <br></br>

          <Link to="/questionnaires" style={{ textDecoration: "none" }}>
            <div className="button" id="backBtn">
              {" "}
              חזרה
            </div>
          </Link>
          <div className="lineDice" />
        </div>
      </div>
    </form>
  );
};

export default BlogRomy;
